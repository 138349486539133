import React, { useState, useEffect } from "react";
import { NumericFormat } from "react-number-format";
import {
  TextField,
  Button,
  CircularProgress,
  Alert,
  Snackbar,
  MenuItem,
  Card,
  CardContent,
  Typography,
  Checkbox,
  Box,
} from "@mui/material";
import axios from "axios";
import { createFolio } from "../../../services/nippon.service";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useAppContext } from "../../../Store/AppContext";
import {
  FormatNumber,
  FormatNumberRedeem,
  dateConverter,
  parseNumber,
} from "../../../utils/formatNumber";
import {
  ProductCode,
  BankName,
  PaymentMode,
  isholiday,
  Name_Days,
} from "../../../Data/data";

const RedeemCreate = ({ accessToken }) => {
  const getDate = () => {
    var currentDate = new Date();
    var day = String(currentDate.getDate()).padStart(2, "0");
    var month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    var year = currentDate.getFullYear();
    return month + "/" + day + "/" + year;
  };
  const Navigate = useNavigate();
  const { folio_id } = useParams();
  const [storeState, dispatch] = useAppContext();
  const { state }: any = useLocation();
  const { value, data } = state;
  const [isAllUnits, setAllUnits] = useState(false);
  console.log("value : ",value)
  const [formData, setFormData] = useState({
    fund: ProductCode.filter(
      (ele) => ele.name.toUpperCase() == value?.Fund_Description.toUpperCase()
    )[0]?.AMC,
    acno: storeState.ACTIVEINVETOR.ACNUM,
    scheme: "DUMMY",
    plan: "DUMMY",
    options: "G",
    RedFlag: "P",
    UnitamtFlag: "A",
    UnitAmtValue: "",
    Tpin: "A",
    bank: storeState.ACTIVEINVETOR.ACNUM ? storeState.ACTIVEINVETOR.ACNUM : " ",
    oldihno: 0,
    trdate: getDate(),
    entdate: getDate(),
    ShowInstaStatus: "Y",
    OTP: "",
    OTPReference: "",
    SelfValidate: "Y",
    deviceid: "PARTNERAPI",
    appVersion: "1.0.1",
    appName: "Klarcap",
    iin: storeState.ACTIVEINVETOR.folio.Folio,
    poa: "N",
    poa_bank_trxn_type: "",
    trxn_acceptance: "OL",
    dp_id: "",
    acc_no: storeState.ACTIVEINVETOR?.ACNUM,
    bank_name: storeState.ACTIVEINVETOR?.BANK,
    ifsc_code: storeState.ACTIVEINVETOR?.IFSC,
    remarks: "test",
    trxn_initiator: "I/O",
    trans_count: 1,
    investor_auth_log: "",
    amc: ProductCode.filter(
      (ele) =>
        ele?.AMC == value?.AMC && value?.Product_Code.includes(ele.scheme_code)
    )[0]?.AMC,
    folio: "",
    product_code: ProductCode.filter(
      (ele) =>
        ele?.AMC == value?.AMC && value?.Product_Code?.includes(ele.scheme_code)
    )[0]?.code,
    ft_acc_no: "",
    amt_unit_type: "Unit",
    amt_unit: "",
    all_units: "N",
    input_ref_no: "",
    fundType: "Various funds through NSE",
    total_investment: `${FormatNumber(value.currentValue)}`,
    pan: storeState?.ACTIVEINVETOR?.folio?.pan,
    view_amount: "",
    NAV_latest: value.NAV_latest,
    nav_value: value?.NAV_latest[0]?.NAV,
    total_units: value.currentUnits,
    approx_redeem_value: 0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [phone, setPhone] = useState("");
  const [Msg, setMsg] = useState("");
  const [validationErrors, setValidationErrors] = useState<any>({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "checked") {
      const { checked } = event.target;
      checked ? setAllUnits(true) : setAllUnits(false);
      const redeemAmount =
        Number(formData.total_units) *
        Number(formData.nav_value);
      setFormData((prevData) => ({
        ...prevData,
        ["amt_unit"]: formData.total_units,
        ["view_amount"]: formData.total_units,
        ["all_units"]: checked ? "Y" : "N",
        ["approx_redeem_value"]: redeemAmount,
      }));
    } else if (name === "view_amount") {
      const redeemAmount =
        Number(value.replaceAll(',','')) * Number(formData.nav_value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        ["amt_unit"]: parseNumber(value).toString(),
        ["all_units"]: "N",
        ["approx_redeem_value"]: redeemAmount,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationErrors({});
    setIsLoading(true);
    if (Number(value.currentValue) < Number(formData.amt_unit)) {
      setMsg(
        "Redeem units should be less than or equal to total units"
      );
      setIsFailure(true);
      setIsLoading(false);
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/redeem`,
        formData,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => {
        // navigate(`/dashboardSuper/investment`)
        const { data } = res;
        if (!data.succ) {
          setIsFailure(true);
          setIsLoading(false);
          setMsg(data.message);
          return;
        }
        setIsSuccess(true);
        setIsLoading(false);
        setMsg(
          `Redeem request submitted successfully for Rs ${formData.view_amount}`
        );
        setTimeout(() => {
          Navigate(
            `/dashboardAdmin/investment/nse/details/${storeState.ACTIVEINVETOR.folio.Folio}`
          );
        }, 3000);
      })
      .catch(({ response }) => {
        setIsLoading(false);
        const { data } = response;
        setIsFailure(true);
        setMsg("Validation Errors!");
        setValidationErrors(data.validationErrors);
      });
  };

  useEffect(() => {
    try {
      const res = axios
        .get(`${process.env.REACT_APP_BACKEND_HOST}v1/user/investment/folio`, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((res) => {
          const { data } = res;
          const phone = data.phone + "";
          setPhone(phone.slice(-2));
        });
    } catch (error) {
      setIsFailure(true);
      setMsg("Unable to Fetch Phone Number From Folio DB");
      return;
    }
  }, []);

  const handleCloseSnackbar = () => {
    setIsFailure(false);
  };

  return (
    <>
      {["Sunday", "Saturday"].includes(Name_Days) ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              background: "yellow",
              padding: "4px",
              borderRadius: "10px",
              textAlign: "center",
              width: { xs: "90%", sm: "600px" },
            }}
          >{`Kindly note that today is a holiday as exchanges are closed on ${Name_Days}. Also, ${dateConverter(
            isholiday?.date
          )} is a holiday on account of ${isholiday?.Desc}.`}</Typography>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <Typography
            sx={{
              background: "yellow",
              padding: "4px",
              borderRadius: "10px",
              textAlign: "center",
              width: { xs: "90%", sm: "600px" },
            }}
          >{`Kindly note that ${dateConverter(
            isholiday?.date
          )} is a holiday on account of ${isholiday?.Desc}.`}</Typography>
        </div>
      )}
      <Card sx={{ maxWidth: 600, margin: "0 auto" }}>
        <CardContent>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <Typography variant="subtitle1" gutterBottom>
              Redeem Request
            </Typography>
            {/* <TextField
            label="Fund Name" 
            name="fundType"
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.fund}
            helperText={validationErrors.fund}
            required
            select
            >
            {Funds.map((ele, indx) => {
              return <MenuItem key={indx} value={ele.code} >{ele.title}</MenuItem>
            })}
            </TextField>
            {formData.fundType == "RMF" && <>
            <TextField
            label="Your Bank"
            name="bank"
            defaultValue={formData.bank}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.bank}
            helperText={validationErrors.bank}
            disabled
            required
            />
            <TextField
            label="Scheme"
            name="scheme"
            onChange={handleChange}
            defaultValue={schemes[0].value}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.Scheme}
            helperText={validationErrors.Scheme}
            select
            required
            >
            {schemes.map(ele => {
              return (
                <MenuItem value={ele.value} defaultChecked key={ele.value}>
                {ele.name}
                </MenuItem>
                );
              })}
              </TextField>
              
              <TextField
              label="Amount"
              name="UnitAmtValue"
              value={formData.UnitAmtValue}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!validationErrors.UnitAmtValue}
              helperText={validationErrors.UnitAmtValue}
              type="number"
              required
              />
              
            </>} */}

            {/* <TextField
                label="Bank Name"
                name="bank_name"
                value={formData.bank_name}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!validationErrors.bank_name}
                helperText={validationErrors.bank_name}
                select
                required
                >
                {
                  BankName.map((ele,indx)=>{
                    return<MenuItem key={indx} value={ele.code} >{ele.title}</MenuItem>
                  })
                }
                </TextField>
                
                <TextField
                label="IFSC"
                name="ifsc_code"
                value={formData.ifsc_code}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!validationErrors.ifsc_code}
                helperText={validationErrors.ifsc_code}
              /> */}
            <Box sx={{ display: "flex", flexDirection: "rows", gap: "10px" }}>
              <TextField
                label="Total Units"
                name="total_investment"
                value={FormatNumberRedeem(formData.total_units)}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
              />
              <TextField
                label="Approx. Value"
                name="total_investment"
                value={FormatNumberRedeem(
                  Number(formData.total_units) * Number(formData.nav_value)
                )}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "rows", gap: "10px" }}>
              <NumericFormat
                type="text"
                label="Redeem Units"
                name="view_amount"
                value={formData.view_amount}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
                fullWidth
                error={!!validationErrors.view_amount}
                helperText={validationErrors.view_amount || "Multiple of 0.001"}
                required
                thousandsGroupStyle="lakh"
                thousandSeparator=","
                customInput={TextField}
                decimalScale={3}
                disabled={isAllUnits}
              />
              <TextField
                label="Approx. Value"
                value={FormatNumberRedeem(formData.approx_redeem_value)}
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
                error={!!validationErrors.amt_unit}
                helperText={validationErrors.amt_unit}
              />
            </Box>
            {/* <TextField
            label="Redeemption Amount"
            name="amt_unit"
            value={formData.amt_unit}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            required
            error={!!validationErrors.amt_unit}
            helperText={validationErrors.amt_unit}
          /> */}
            <Checkbox name="checked" onChange={handleChange} />
            <span>Redeem entire units</span>
            {/* <TextField
            label="OTP"
            name="OTP"
            value={formData.OTP}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.OTP}
            helperText={validationErrors.OTP}
          /> */}

            {/* <TextField
            label="Account Number"
            name="acno"
            value={formData.acno}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.acno}
            helperText={validationErrors.acno}
            disabled
            /> 
            
            
            <TextField
            label="Plan"
            name="plan"
            value={formData.plan}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.plan}
            helperText={validationErrors.plan}
            /> 
            
            <TextField
            label="Options"
            name="options"
            value={formData.options}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.options}
            helperText={validationErrors.options}
            />
            
            <TextField
            label="Red Flag"
            name="RedFlag"
            value={formData.RedFlag}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.RedFlag}
            helperText={validationErrors.RedFlag}
            />
            
            <TextField
            label="Unit Amount Flag"
            name="UnitamtFlag"
            value={formData.UnitamtFlag}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.UnitamtFlag}
            helperText={validationErrors.UnitamtFlag}
            />
            
            
            
            <TextField
            label="Tpin"
            name="Tpin"
            value={formData.Tpin}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.Tpin}
            helperText={validationErrors.Tpin}
            /> 
            
            <TextField
            label="Old IHNO"
            name="oldihno"
            value={formData.oldihno}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.oldihno}
            helperText={validationErrors.oldihno}
            />
            
            <TextField
            label="Transaction Date"
            name="trdate"
            value={formData.trdate}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            disabled
            error={!!validationErrors.trdate}
            helperText={validationErrors.trdate}
            />
            
            <TextField
            label="Entry Date"
            name="entdate"
            value={formData.entdate}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.entdate}
            helperText={validationErrors.entdate}
            disabled
            />
            
            <TextField
            label="Show Insta Status"
            name="ShowInstaStatus"
            value={formData.ShowInstaStatus}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.ShowInstaStatus}
            helperText={validationErrors.ShowInstaStatus}
            />
            
            
            <TextField
            label="OTP Reference"
            name="OTPReference"
            value={formData.OTPReference}
            disabled
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.OTPReference}
            helperText={validationErrors.OTPReference}
            // disabled
            />
            
            <TextField
            label="Self Validate"
            name="SelfValidate"
            value={formData.SelfValidate}
            onChange={handleChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!validationErrors.SelfValidate}
            helperText={validationErrors.SelfValidate}
          /> */}
            <TextField
              label="Scheme"
              name="product_code"
              onChange={handleChange}
              value={formData.product_code}
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!validationErrors.product_code}
              helperText={validationErrors.product_code}
              required
              select
              disabled
            >
              {ProductCode.filter(
                (ele) =>
                  ele.AMC == formData.amc && ele.code == formData.product_code
              ).map((each, idx) => (
                <MenuItem key={idx} value={each.code}>
                  {each.name}
                </MenuItem>
              ))}
            </TextField>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading}
              fullWidth
              sx={{ marginTop: 2 }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </CardContent>
        <Snackbar
          open={isSuccess}
          autoHideDuration={3000}
          onClose={() => setIsSuccess(false)}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="success">{`Submitted. Now, kindly approve the redemption in the email received from NSE.`}</Alert>
        </Snackbar>
        <Snackbar
          open={isFailure}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          sx={{ marginBottom: 2 }}
        >
          <Alert severity="error">{Msg}</Alert>
        </Snackbar>
      </Card>
    </>
  );
};

export default RedeemCreate;
