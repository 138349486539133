const ProductCode = [
  {
    id: 1,
    code: "AFWG",
    scheme_code: "HAFWG",
    AMC: "H",
    name: "HDFC Arbitrage Fund - Wholesale Plan - Regular Plan - Growth",
  },
  {
    id: 2,
    code: "GFG",
    scheme_code: "HGFG",
    AMC: "H",
    name: "HDFC Balanced Advantage Fund - Regular Plan - Growth",
  },
  {
    id: 3,
    code: "02",
    scheme_code: "H02",
    AMC: "H",
    name: "HDFC Flexi Cap Fund - Regular Plan - Growth",
  },
  {
    id: 4,
    code: "HLFGN",
    scheme_code: "HLFGN",
    AMC: "H",
    name: "HDFC Liquid Fund - Regular Plan - Growth",
  },
  {
    id: 5,
    code: "54",
    scheme_code: "H54",
    AMC: "H",
    name: "HDFC Low Duration Fund - Regular Plan - Growth",
  },
  {
    id: 6,
    code: "57N",
    scheme_code: "H57N",
    AMC: "H",
    name: "HDFC Overnight Fund - Regular Plan -  Growth",
  },
  {
    id: 7,
    code: "USTGR",
    scheme_code: "HUSTGR",
    AMC: "H",
    name: "HDFC Ultra Short Term Fund - Regular Growth",
  },
  {
    id: 8,
    code: "EDIRG",
    scheme_code: "PEDIRG",
    AMC: "P",
    name: "ICICI Prudential Equity Arbitrage Fund - Growth",
  },
  {
    id: 9,
    code: "1565",
    scheme_code: "P1565",
    AMC: "P",
    name: "ICICI Prudential Liquid Fund - Regular plan - Growth",
  },
  {
    id: 10,
    code: "3491",
    scheme_code: "P3491",
    AMC: "P",
    name: "ICICI Prudential Overnight Fund Growth",
  },
  {
    id: 11,
    code: "1746",
    scheme_code: "P1746",
    AMC: "P",
    name: "ICICI Prudential Ultra Short Term Fund - Growth",
  },
  {
    id: 12,
    code: "LFGPGGR",
    scheme_code: "LFGP",
    AMC: "127",
    name: "Motilal Oswal Liquid Fund - Regular Growth",
  },
  {
    id: 13,
    code: "USGPGGR",
    scheme_code: "USGP",
    AMC: "127",
    name: "Motilal Oswal Ultra Short Term Fund - Growth",
  },
  {
    id: 14,
    code: "AFGPGR",
    scheme_code: "AFGP",
    AMC: "RMF",
    name: "NIPPON INDIA Arbitrage Fund - GROWTH PLAN - GROWTH",
  },
  {
    id: 15,
    code: "LFIGGR",
    scheme_code: "LFIG",
    AMC: "RMF",
    name: "NIPPON INDIA LIQUID FUND - GROWTH PLAN - GROWTH OPTION",
  },
  {
    id: 16,
    code: "LPIGGR",
    scheme_code: "LPIG",
    AMC: "RMF",
    name: "NIPPON INDIA Low Duration Fund - Growth Plan Growth Option",
  },
  {
    id: 17,
    code: "ONGPGR",
    scheme_code: "ONGP",
    AMC: "RMF",
    name: "NIPPON INDIA OVERNIGHT FUND - GROWTH PLAN",
  },
  {
    id: 18,
    code: "CPGPGR",
    scheme_code: "CPGP",
    AMC: "RMF",
    name: "NIPPON INDIA Ultra Short Duration Fund - Growth Option",
  },
  {
    id: 19,
    code: "PEGPGGR",
    scheme_code: "PEGP",
    AMC: "166",
    name: "Quant Flexi Cap Fund - Regular Growth Plan-Growth",
  },
  {
    id: 20,
    code: "LFGPGGR",
    scheme_code: "LFGP",
    AMC: "166",
    name: "Quant Liquid Plan-Regular Growth Plan-Growth",
  },
  {
    id: 21,
    code: "OFGPGGR",
    scheme_code: "OFGP",
    AMC: "166",
    name: "Quant Mid Cap Fund - Regular Growth Plan-Growth",
  },
  {
    id: 22,
    code: "IBGPGGR",
    scheme_code: "IBGP",
    AMC: "166",
    name: "Quant Small Cap Fund - Regular Growth Plan-Growth",
  },
  {
    id: 23,
    code: "114G",
    scheme_code: "L114G",
    AMC: "L",
    name: "SBI Arbitrage Opportunities Fund - Regular Plan - Growth",
  },
  {
    id: 24,
    code: "72SG",
    scheme_code: "L72SG",
    AMC: "L",
    name: "SBI Liquid Fund Regular Growth",
  },
  {
    id: 25,
    code: "F47RG",
    scheme_code: "LF47RG",
    AMC: "L",
    name: "SBI Magnum Low Duration Fund Regular Growth",
  },
  {
    id: 25,
    code: "086G",
    scheme_code: "L086G",
    AMC: "L",
    name: "SBI Magnum Ultra Short Duration Fund Regular Growth",
  },
  {
    id: 26,
    code: "57G",
    scheme_code: "L57G",
    AMC: "L",
    name: "SBI Overnight Fund Regular Growth",
  },
  {
    id: 26,
    code: "PP001RG",
    scheme_code: "PP001RG",
    AMC: "PP",
    name: "Parag Parikh Flexi Cap Fund - Regular Plan - Growth",
  },
];

const BankName = [
  { code: "AUB", title: "AU Small Finance Bank" },
  { code: "AXC", title: "Axis Bank Corporate" },
  { code: "UTI", title: "Axis Bank Ltd" },
  { code: "BNPP", title: "BNP Paribas Bank" },
  { code: "BDN", title: "Bandhan Bank" },
  { code: "BBC", title: "Bank of Baroda - Corporate Banking" },
  { code: "BBR", title: "Bank of Baroda - Retail Banking" },
  { code: "BOI", title: "Bank of India" },
  { code: "BOM", title: "Bank of Maharashtra" },
  { code: "CIT", title: "CITI BANK" },
  { code: "CNB", title: "Canara Bank" },
  { code: "CSBF", title: "Capital Small Bank Finance Ltd" },
  { code: "CBI", title: "Central Bank of India" },
  { code: "CUB", title: "City Union Bank" },
  { code: "DBK", title: "Deutsche Bank" },
  { code: "EQB", title: "Equitas Small Finance Bank" },
  { code: "FBK", title: "Federal Bank" },
  { code: "HDF", title: "HDFC Bank Ltd" },
  { code: "HSB", title: "HSBC" },
  { code: "ICI", title: "ICICI Bank Ltd" },
  { code: "ICO", title: "ICICI Bank Ltd - Corporate" },
  { code: "IDB", title: "IDBI Bank" },
  { code: "IDC", title: "IDBI Corporate" },
  { code: "IDN", title: "IDFC First Bank" },
  { code: "INB", title: "INDIAN BANK" },
  { code: "IDS", title: "IndusInd Bank" },
  { code: "KBL", title: "Karnataka Bank Ltd" },
  { code: "KVB", title: "Karur Vysya Bank" },
  { code: "162", title: "Kotak Bank" },
  { code: "CPN", title: "Punjab National Bank - Corporate Banking" },
  { code: "PNB", title: "Punjab National Bank - Retail Banking" },
  { code: "RBL", title: "Ratnakar Bank" },
  { code: "RTC", title: "Ratnakar Corporate Banking" },
  { code: "SIB", title: "South Indian Bank" },
  { code: "SCB", title: "Standard Chartered Bank" },
  { code: "SBI", title: "State Bank of India" },
  { code: "SBOM", title: "State Bank of Mauritius Ltd" },
  { code: "UBI", title: "Union Bank of India" },
  { code: "YBK", title: "Yes Bank Ltd" },
];

const PaymentMode = [
  { code: "M", name: "Debit Mandate" },
  { code: "OL", name: "Online" },
  { code: "TR", name: "RTGS / NEFT" },
  { code: "UPI", name: "UPI" },
];

//new Date().toLocaleDateString() -> '27/09/2023' 2023-09-29T23:59:59.000Z
const PublicHolidays = [
  {
    id: 0,
    date: "2024-01-21T23:59:59.000Z",
    Day: "Monday",
    Desc: "Special Holiday",
  },
  {
    id: 1,
    date: "2024-01-25T23:59:59.000Z",
    Day: "Friday",
    Desc: "Republic Day",
  },
  {
    id: 2,
    date: "2024-02-18T23:59:59.000Z",
    Day: "Monday",
    Desc: "Chhatrapati Shivaji Maharaj Jayanti",
  },
  {
    id: 3,
    date: "2024-03-07T23:59:59.000Z",
    Day: "Friday",
    Desc: "Mahashivratri",
  },
  {
    id: 4,
    date: "2024-03-24T23:59:59.000Z",
    Day: "Monday",
    Desc: "Holi",
  },
  {
    id: 5,
    date: "2024-03-28T23:59:59.000Z",
    Day: "Friday",
    Desc: "Good Friday",
  },
  {
    id: 6,
    date: "2024-03-31T23:59:59.000Z",
    Day: "Monday",
    Desc: "Annual Bank closing",
  },
  {
    id: 7,
    date: "2024-04-08T23:59:59.000Z",
    Day: "Tuesday",
    Desc: "Gudi Padwa",
  },
  {
    id: 8,
    date: "2024-04-10T23:59:59.000Z",
    Day: "Thursday",
    Desc: "Id-Ul-Fitr (Ramadan Eid)",
  },
  {
    id: 9,
    date: "2024-04-16T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Ram Navami",
  },
  {
    id: 10,
    date: "2024-04-30T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Maharashtra Day",
  },
  {
    id: 11,
    date: "2024-05-22T23:59:59.000Z",
    Day: "Thursay",
    Desc: "Buddha Pournima",
  },
  {
    id: 12,
    date: "2024-06-16T23:59:59.000Z",
    Day: "Monday",
    Desc: "Bakri Eid",
  },
  {
    id: 13,
    date: "2024-07-16T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Moharram",
  },
  {
    id: 14,
    date: "2024-08-14T23:59:59.000Z",
    Day: "Thursday",
    Desc: "Independence Day/ Parsi New Year",
  },
  {
    id: 15,
    date: "2024-09-15T23:59:59.000Z",
    Day: "Monday",
    Desc: "Eid-e-Milad",
  },

  {
    id: 16,
    date: "2024-10-01T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Mahatma Gandhi Jayanti",
  },
  {
    id: 17,
    date: "2024-10-31T23:59:59.000Z",
    Day: "Friday",
    Desc: "Diwali-Laxmi Pujan",
  },
  {
    id: 18,
    date: "2024-11-14T23:59:59.000Z",
    Day: "Friday",
    Desc: "Guru Nanak Jayanti",
  },
  {
    id: 19,
    date: "2024-12-24T23:59:59.000Z",
    Day: "Wednesday",
    Desc: "Christmas",
  },
];

const STATE = [
  { code: "AN", value: "Andaman and Nicobar Islands" },
  { code: "AP", value: "Andhra Pradesh" },
  { code: "AR", value: "Arunachal Pradesh" },
  { code: "AS", value: "Assam" },
  { code: "BH", value: "Bihar" },
  { code: "CH", value: "Chandigarh" },
  { code: "CG", value: "Chhattisgarh" },
  { code: "DN", value: "Dadra and Nagar Haveli" },
  { code: "DD", value: "Daman and Diu" },
  { code: "GO", value: "Goa" },
  { code: "GU", value: "Gujarat" },
  { code: "HA", value: "Haryana" },
  { code: "HP", value: "Himachal Pradesh" },
  { code: "KR", value: "Jammu and Kashmir" },
  { code: "JD", value: "Jharkhand" },
  { code: "KA", value: "Karnataka" },
  { code: "KE", value: "Kerala" },
  { code: "LD", value: "Lakshadweep" },
  { code: "MP", value: "Madhya Pradesh" },
  { code: "MA", value: "Maharashtra" },
  { code: "MN", value: "Manipur" },
  { code: "ME", value: "Meghalaya" },
  { code: "MI", value: "Mizoram" },
  { code: "NA", value: "Nagaland" },
  { code: "ND", value: "New Delhi" },
  { code: "OD", value: "ODISHA" },
  { code: "OT", value: "Others" },
  { code: "PO", value: "Puducherry" },
  { code: "PU", value: "Punjab" },
  { code: "RA", value: "Rajasthan" },
  { code: "SI", value: "Sikkim" },
  { code: "TN", value: "Tamil Nadu" },
  { code: "TE", value: "Telangana" },
  { code: "TR", value: "Tripura" },
  { code: "UP", value: "Uttar Pradesh" },
  { code: "UR", value: "Uttarakhand" },
  { code: "WB", value: "West Bengal" },
];

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const date = new Date().toISOString();
const Name_Days = dayNames[new Date().getDay()];
const isholiday = PublicHolidays.filter(
  (ele) => new Date(ele.date).getTime() > new Date(date).getTime()
)[0];
module.exports = {
  ProductCode,
  BankName,
  PaymentMode,
  isholiday,
  Name_Days,
  STATE,
};
