import React, { useState ,useEffect} from 'react'
import { Box, CardContent, TextField, Card, Button, MenuItem, CircularProgress, Typography, Snackbar, Alert } from '@mui/material'
import { validateEmail, validatePassword } from "../../../utils/validators";
import axios from 'axios';
import { user } from "../../../utils/interface";
import { useNavigate, useSearchParams } from 'react-router-dom';
const Password = ({ nextHandler, formData, setformData }) => {
  const [isLoading, setLoading] = useState(false)
  const [isOTPSent, setOTPSent] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(true);
  const [isError, setError] = useState(false);
  const [validating, setValidating] = useState<boolean>(false);
  const [Msg, setMsg] = useState("");
  const Navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [newUser, setNewUser] = useState<user>({
    name: "",
    companyName: "",
    panNumber: "",
    mobileNumber: "",
    email: "",
    password: "",
    token: ''
  });

  const changeHandler = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name == "password" && value == "") {
      setValidating(false)
      setValidationErrors({

      })
    }
    setformData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  useEffect(() => {
    const id = searchParams.get("code");
    axios
      .post(process.env.REACT_APP_BACKEND_HOST + 'v1/admin/data', { id })
      .then(({ data }) => {
        setNewUser(data.detail)
      });
    let tokens = localStorage.getItem("tokens");
    try {
      if (tokens) window.location.href = "/dashboard";
      else setIsLoggedIn(false);
    } catch (err) {
      localStorage.removeItem("tokens");
      setIsLoggedIn(false);
    }
  }, []);

  const stepHandler = () => {

    setValidating(true);
    if (!validatePassword(formData.password)) return;
    setValidating(false);

    if (formData.name == "" || formData.email == "" || formData.mobileNumber == "" || formData.password == "" || formData.confirmPassword == "") {
      setError(true);
      setMsg("Please, Fill the required inputs!!!");
      return;
    } else if (formData.password !== formData.confirmPassword) {
      setError(true);
      setMsg("Password Not Match!!!");
      return;
    }
    let url = "v1/admin/nse/register";
    if (searchParams.get("code")) {
      url = "v1/member/setPassword";
    }
    const payload: any = {
      ...formData
    }
    if (searchParams.get('token')) {
      payload.token = `${searchParams.get('token')}`
    }
    setLoading(true);
    axios
      .post(process.env.REACT_APP_BACKEND_HOST + url, { ...payload })
      .then((response) => {
        const { data } = response;
        if (data.success) {
          setSuccess(true);
          setMsg(data.message)
          if (searchParams.get("code")) {
            window.location.href = '/login';
          }
          setTimeout(() => {
            Navigate("/")
          }, 4000);
          setLoading(false)
        } else {
          setError(true)
          setMsg(data.message)
          setLoading(false)
          return
        }
      })
      .catch((err) => {
        setError(true)
        setMsg(err.response.data.message);
        setLoading(false)
      });
  }

  return (
    <Box sx={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
      <Card  sx={{width:{md:"42vw",sm:"75vw",xs:"97vw"}, p: 1}}>
        <Snackbar open={isError} autoHideDuration={5000} onClose={() => setError(false)} >
          <Alert severity='error' >{Msg}</Alert>
        </Snackbar>
        <Snackbar open={isSuccess} autoHideDuration={5000} onClose={() => setOTPSent(false)} >
          <Alert severity='success' >{Msg}</Alert>
        </Snackbar>
        <CardContent  >
          <form onSubmit={nextHandler} style={{ width: '100%',display:"flex",flexDirection:"column",gap:2 }}>
            <Box sx={{ display: "flex", flexDirection:{sm:"row",xs:"column"}, width: "100%",alignItems:{md: "center",sm:"center",xs:"" },justifyContent:"center"}}>
              <Typography sx={{ width:{xs:"100%",sm :"30%"}, fontSize: "1.1rem", fontWeight: "600" }}> Password </Typography>
              <TextField
                placeholder="passwordexample123"
                variant="outlined"
                fullWidth
                type="password"
                name="password"
                value={formData.password}
                onChange={changeHandler}
                required
                error={
                  validationErrors.password || !validatePassword(formData.password)
                }
                helperText={
                  validationErrors.password || !validatePassword(formData.password)
                    ? "Password must be atleast 8 characters and contain atleast one character and one number"
                    : ""
                }
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection:{sm:"row",xs:"column"}, width: "100%",alignItems:{md: "center",sm:"center",xs:"" },justifyContent:"center"}}>
              <Typography sx={{ width:{xs:"100%",sm :"30%"}, fontSize: "1.1rem", fontWeight: "600" }}>Confirm Password </Typography>
              <TextField
                placeholder="passwordexample123"
                variant="outlined"
                fullWidth
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={changeHandler}
                required
                error={
                  validationErrors.password && !validatePassword(formData.confirmPassword)
                }
                helperText={
                  validationErrors.password && !validatePassword(formData.confirmPassword)
                    ? "Password must be atleast 8 characters and contain atleast one character and one number"
                    : ""
                }
              />
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={stepHandler}
              sx={{ marginTop: 2, marginLeft: "35%", width: "30%" }}
              disabled={isLoading}
            >
              {isLoading && <CircularProgress style={{ width: "20px", height: "20px", marginRight: "10px", color: "white" }} />} {'SUBMIT'}
            </Button >
          </form>
        </CardContent>
      </Card>
    </Box >
  )
}
export default Password